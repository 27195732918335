'use strict';

angular.module('enervexSalesappApp').controller('SystemTasksCtrl', function($scope, SystemTask, _, Flash, $rootScope) {
	$scope.all = [];
	$scope.offset = 0;
	$scope.limit = 50;
	$scope.ctx = {
		hasMore: true
	}
	function fetch() {
		SystemTask.query({
			offset:$scope.offset,
			limit: $scope.limit
		}).$promise.then(function(items){
			if (items.length > 0) {
				$scope.all = $scope.all.concat(items);
				$scope.offset = $scope.offset + $scope.limit;
				$scope.all = _.sortBy($scope.all, function(o){
					return o.sortOrder * 1
				})
			} else {
				$scope.ctx.hasMore = false
			}
			$scope.items = $scope.all
		});
	}
	fetch();

	$scope.nextPage = function() {
		fetch()
	}
	// $scope.delete = function(item) {
	// 	SystemTask.delete({
	// 		id: item._id
	// 	});
	// 	$scope.all = _.filter($scope.all, function(r){
	// 		return r._id != item._id
	// 	})
	// 	$scope.items = $scope.all;
	// 	// $scope.applyFilter()
	// }
	// $scope.applyFilter = function() {
	// 	var nameReg = ($scope.term && $scope.term != '') ? RegExp($scope.term, "i") : null;

	// 	$scope.fans = _.filter($scope.allVentMaterials, function(rule){
	// 		if (nameReg && !nameReg.test(rule.code) ) {
	// 			return false;
	// 		} else if ($scope.computeAs== 'BWH' && !rule.BWH) {
	// 			return false;
	// 		} else if ($scope.computeAs== 'COM' && !rule.COM) {
	// 			return false;
	// 		} else if ($scope.computeAs== 'DRY' && !rule.DRY) {
	// 			return false;
	// 		} else {
	// 			return true
	// 		}
	// 	})
	// }
});
